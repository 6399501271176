<template>
  <div id="Submitter">
    <CSBreadcrumb/>
    <div class="result-panel">
      <table class="table">
        <thead>
          <tr style="text-align: center">
            <th>提交时间</th>
            <th>身份</th>
            <th>姓名</th>
            <th>手机号</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(submitter, index) in submitterList" :key="index" style="text-align: center">
            <td>{{ submitter.submitDate }}</td>
            <td>{{ QUESTIONNAIRE_SEND_TYPE_STR[submitter.userType]}}</td>
            <td>{{ submitter.userName }}</td>
            <td>{{ submitter.userPhone }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import {
  adminQueryOptionDetailUrl
} from "@/requestUrl"
import {
    QUESTIONNAIRE_SEND_TYPE_STR,
} from "@/constant";

export default {
  name: "Submitter",
  props: {
    id: Number,
    answer: String,
    surveyId: Number,
  },
  components: {
    CSBreadcrumb,
  },
  data() {
    return {
      QUESTIONNAIRE_SEND_TYPE_STR,
      submitterList: [],
    }
  },
  created() {
    this.querySubmitter();
  },
  methods: {
    // 查看提交人
    async querySubmitter() {
      let res = await this.$fly
        .post(adminQueryOptionDetailUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            surveyId: this.surveyId, //问卷id 必填
            answer: this.answer, //选择题选项名称或填空题答案 必填
            questionId: this.id, //题目id 必填
        })
      if (res.code != 0) {
        return;
      }
      this.submitterList = res.data || [];
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>